import detectEthereumProvider from "@metamask/detect-provider";
import React, { useEffect, useState } from "react";
import CurrencyDropdown from "../components/CurrencyDropdown";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { VAULT_ADDRESS } from "../config/address";
import useDeposit from "../hooks/useDeposit";
import btcPng from "../images/btc.png";
import ethPng from "../images/eth.png";
import productBenifitsPng from "../images/product-benifits.png";
import productVaultBg from "../images/product-vault-bg.png";
import usdcPng from "../images/usdc.png";
import usdtPng from "../images/usdt.png";
import { formatBalance } from "../services/utils";
import { useWallet } from "../services/walletContextProvider";
import { INFURA_API_KEY } from "../config/constants";
import "./styles/product.css";

export default function Product() {
    window.scrollTo(0, 0);

    const productTitle = "Single Stock Vault";
    const productSubTitle = "Electric";
    const apy = "20%";
    const downSideProtection = 21.00;
    const linkedAsset = "TESLA";
    const productTVL = "$486,645.64";
    const tokenStandard = "Erc20";

    const [amount, setAmount] = useState("");

    const handleChange = (e) => {
        const value = e.target.value;
        if (/^\d*(\.\d{0,2})?$/.test(value)) {
            setAmount(value);
        }
    };

    const handleBlur = () => {
        if (amount) {
            setAmount(parseFloat(amount).toFixed(2));
        }
    };

    const options = [
        { value: "usdc", label: "USDC", icon: usdcPng },
        { value: "usdt", label: "USDT", icon: usdtPng },
        { value: "btc", label: "BTC", icon: btcPng },
    ];

    const handleDropdownChange = (selectedOption) => {
        console.log("selected: ", selectedOption);
    };

    // Wallet
    const [isConnected, setIsConnected] = useState(false);
    const { depositToContract, isPending } = useDeposit();
    const [hasProvider, setHasProvider] = useState(null);
    const initialState = {
        accounts: [],
        balance: "",
        chainId: "",
    };
    const { setWallet } = useWallet();

    useEffect(() => {
        const refreshAccounts = (accounts) => {
            if (accounts.length > 0) {
                updateWallet(accounts);
            } else {
                // if length 0, user is disconnected
                setWallet(initialState);
            }
        };

        const refreshChain = (chainId) => {
            setWallet((wallet) => ({ ...wallet, chainId }));
        };

        const getProvider = async () => {
            const provider = await detectEthereumProvider({ silent: true });
            setHasProvider(Boolean(provider));

            if (provider) {
                const accounts = await window.ethereum.request({
                    method: "eth_accounts",
                });
                refreshAccounts(accounts);
                window.ethereum.on("accountsChanged", refreshAccounts);
                window.ethereum.on("chainChanged", refreshChain);

                if (accounts.length > 0) {
                    setIsConnected(true);
                }
            }
        };

        getProvider();

        return () => {
            window.ethereum?.removeListener("accountsChanged", refreshAccounts);
            window.ethereum?.removeListener("chainChanged", refreshChain);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateWallet = async (accounts) => {
        const balance = formatBalance(
            await window.ethereum.request({
                method: "eth_getBalance",
                params: [accounts[0], "latest"],
            })
        );
        const chainId = await window.ethereum.request({
            method: "eth_chainId",
        });
        setWallet({ accounts, balance, chainId });
    };

    const handleConnect = async () => {
        try {
            await switchToGoerli();

            let accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            updateWallet(accounts);

            setIsConnected(true);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeposit = async () => {
        if (amount && parseFloat(amount) > 0) {
            try {
                await depositToContract(VAULT_ADDRESS, amount);
            } catch (error) {
                console.error("Error during deposit:", error);
            }
        } else {
            alert("Please enter a valid deposit amount.");
        }
    };

    const rpc_url = `https://goerli.infura.io/v3/${INFURA_API_KEY}`

    const switchToGoerli = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xaa36a7' }],
            });
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: '0xaa36a7',
                            chainName: 'Sepolia Testnet',
                            nativeCurrency: {
                                name: 'SEPOLIA ETH',
                                symbol: 'seth',
                                decimals: 18
                            },
                            rpcUrls: [rpc_url],
                            blockExplorerUrls: ['https://goerli.etherscan.io/']
                        }],
                    });
                } catch (addError) {
                    console.error(addError);
                }
            } else {
                console.error(switchError);
            }
        }
    };

    return (
        <main>
            <Header isRenderAvatar="true" />

            <div className="container">
                <section className="product-card my-5">
                    <div className="product-card-upper">
                        <img src={productVaultBg} alt="Product Vault" className="product-card-bg" aria-hidden />

                        <h1 className="product-title product-card-padding-x">{productTitle}</h1>
                        <hr className="product-card-divider" />

                        <div className="product-card-padding-x mt-4 pb-4">
                            <h2 className="product-sub-title">{productSubTitle}</h2>

                            <div className="product-apy d-flex">
                                <div className="product-apy-value">{apy}</div>
                                <div className="product-apy-description">Fixed</div>
                            </div>

                            <div className="product-card-details my-4 d-flex">
                                <div className="product-card-detail-box">
                                    <div className="product-card-detail-label">
                                        LINKED <br /> ASSET
                                    </div>
                                    <div className="product-card-detail-value">
                                        {linkedAsset}
                                    </div>
                                </div>

                                <div className="product-card-detail-box">
                                    <div className="product-card-detail-label">
                                        DOWNSIDE <br /> PROTECTION
                                    </div>
                                    <div className="product-card-detail-value">
                                        {downSideProtection.toFixed(2)}%
                                    </div>
                                </div>

                                <div className="product-card-detail-box">
                                    <div className="product-card-detail-label">
                                        PRODUCT <br /> TVL
                                    </div>
                                    <div className="product-card-detail-value">
                                        {productTVL}
                                    </div>
                                </div>

                                <div className="product-card-detail-box">
                                    <div className="product-card-detail-label">
                                        STACKING <br /> ASSET
                                    </div>
                                    <div className="product-card-detail-value">
                                        <img src={usdcPng} alt="USDC" className="product-card-detail-image" />
                                        <img src={usdtPng} alt="TUSD" className="product-card-detail-image" />
                                        <img src={btcPng} alt="BTC" className="product-card-detail-image" />
                                    </div>
                                </div>
                            </div>

                            <div className="product-deposit-container">
                                <h3 className="product-deposit-title">Deposit</h3>
                                <div className="product-deposit-label">Amount:</div>

                                <div className="d-flex align-items-center">
                                    <input className="product-deposit-input"
                                        type="text"
                                        value={amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="0.00"
                                    />

                                    <CurrencyDropdown
                                        options={options}
                                        defaultOption={options[0]}
                                        onChange={handleDropdownChange}
                                    />

                                    <button className="product-deposit-button ms-5"
                                        disabled={!hasProvider || !window.ethereum?.isMetaMask || isPending}
                                        onClick={isConnected ? handleDeposit : handleConnect}>
                                        {isPending ? "LOADING..." : "Deposit Now"}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="product-card-footer product-card-padding-x">
                        <h3 className="product-card-footer-heading">Product Summary</h3>
                        <p>
                            Electric is a tokenized structured product vault that expresses a bullish view on TSLA. This product benefits from the equity appreciation of the leading growth tech stock TSLA.Investors accrue high yield payments on their deposited capital which is paid monthly.
                            The product has an early termination (autocall) feature - if on any autocall valuation date the underlying stock closes above the autocall level, the product will be terminated automatically. Investors will receive their accrued coupon and get their deposited capital in advance, giving them the chance to compound their interest and re-allocate their capital freely.
                        </p>
                    </div>
                </section>

                <section className="product-benifits mb-5 px-5">
                    <div className="row g-5">
                        <div className="col-5">
                            <h2 className="product-benifits-title mt-5">Key Benifits:</h2>
                            <p className="product-benifits-highlight">Earn up to 20.00% APY on USDC deposit</p>
                            <p className="product-benifits-description">
                                Your principal is protected from {downSideProtection.toFixed(2)}% price drop.
                                Your capital is only locked up to 3 months and has chance of early termination to unlock the liquidity. <br />
                                Coupons are paid monthly <br />
                                Mandatory Early Termination
                            </p>
                        </div>
                        <div className="col-7">
                            <img src={productBenifitsPng} alt="Product Benifits" className="product-benifits-image" />
                        </div>
                    </div>
                </section>

                <section className="product-details px-5 mb-5">
                    <div className="row g-3">
                        <div className="col-4">
                            <div className="product-details-container product-details-1 mb-4 p-4">
                                <h3>Product details:</h3>
                                <table className="product-details-table">
                                    <tbody>
                                        <tr>
                                            <td className="product-detail-table-heading">Expected APY</td>
                                            <td className="product-detail-table-value">{apy}</td>
                                        </tr>
                                        <tr>
                                            <td className="product-detail-table-heading">Downside Protection</td>
                                            <td className="product-detail-table-value">21%</td>
                                        </tr>
                                        <tr>
                                            <td className="product-detail-table-heading">Underlying Asset</td>
                                            <td className="product-detail-table-value">TSLA.O</td>
                                        </tr>
                                        <tr>
                                            <td className="product-detail-table-heading">Payment Schedule</td>
                                            <td className="product-detail-table-value">Monthly</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="product-details-container token-details p-4">
                                <h3>Token Details:</h3>
                                <table className="product-details-table">
                                    <tbody>
                                        <tr>
                                            <td className="product-detail-table-heading">Token Standard</td>
                                            <td className="product-detail-table-value">{tokenStandard}</td>
                                        </tr>
                                        <tr>
                                            <td className="product-detail-table-heading">Staking Asset</td>
                                            <td className="product-detail-table-value">
                                                <img src={usdcPng} alt="USDC" className="product-detail-table-image" />
                                                USDC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="product-detail-table-heading">Supporting Chain</td>
                                            <td className="product-detail-table-value">
                                                <img src={ethPng} alt="ETH" className="product-detail-table-image" />
                                                Ethereum
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="product-details-container payoff-details">
                                <h3>Payoff Details:</h3>

                                <p className="payoff-details-subheading"><strong>Autocallable Condition</strong></p>
                                <p className="payoff-details-p">Observe monthly, if on any autocall valuation date the underlying stock closes above the autocall level, the product will be terminated automatically.</p>

                                <p className="payoff-details-subheading"><strong>Normal expiry, closing spot above strike level</strong></p>
                                <p className="payoff-details-p">Investors will receive the last last month coupon and their deposited capital.</p>

                                <p className="payoff-details-subheading"><strong>Normal expiry, closing spot below strike level</strong></p>
                                <p className="payoff-details-p">Investors will receive the last last month coupon and a partial amount of their deposited capital. The final amount would be deposited capital x St/K.</p>

                                <div>
                                    St: stock price at maturity <br />
                                    K: 79% x initial stock price
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <hr className="divider-dash" />

            <div className="container my-5">
                <section className="product-risk px-5">
                    <h4>Risk Disclosure:</h4>

                    <div className="risk-disclosure-content mt-5 px-5">
                        <strong>Market Risk</strong>
                        <p className="mb-0">
                            The value of structured derivatives is highly sensitive to Tesla’s stock price volatility. Large price swings could lead to unanticipated payouts or losses. This risk is magnified given Tesla’s historical volatility and reliance on market sentiment.
                        </p>
                        <strong>Mitigation</strong>
                        <ul>
                            <li>Employ delta hedging strategies to manage exposure to stock price movements.</li>
                            <li>Conduct frequent stress testing under extreme scenarios to prepare for tail risks.</li>
                        </ul>

                        <strong>Counterparty Risk</strong>
                        <p className="mb-0">
                            If Tesla engages in derivative transactions with third parties, there is a risk of default by counterparties (e.g., banks or institutional investors).
                        </p>
                        <strong>Mitigation Strategies:</strong>
                        <ul>
                            <li>Trade primarily with creditworthy counterparties with strong credit ratings.</li>
                            <li>Use collateralization agreements to secure positions (e.g., margin requirements).</li>
                            <li>Diversify counterparties to reduce exposure to any single entity.</li>
                        </ul>

                        <strong>Liquidity Risk</strong>
                        <p className="mb-0">
                            Market conditions could lead to illiquidity, making it challenging for Tesla to unwind or adjust derivative positions without significant costs or delays.
                        </p>
                        <strong>Mitigation Strategies:</strong>
                        <ul>
                            <li>Maintain adequate cash reserves or liquid assets to meet margin calls.</li>
                            <li>Design products with features that align with market liquidity (e.g., shorter maturities, standardized terms).</li>
                            <li>Monitor liquidity metrics and adjust the size of positions accordingly.</li>
                        </ul>

                        <strong>Interest Rate and Currency Risk</strong>
                        <p className="mb-0">
                            Structured derivatives tied to interest rates or denominated in foreign currencies expose Tesla to fluctuations in these variables.
                        </p>
                        <strong>Mitigation Strategies:</strong>
                        <ul>
                            <li>Hedge currency and interest rate exposures with corresponding derivatives (e.g., FX forwards, interest rate swaps).</li>
                            <li>Monitor global macroeconomic trends and adjust exposure proactively.</li>
                            <li>Diversify funding sources to mitigate concentration risks.</li>
                        </ul>
                    </div>
                </section>
            </div>
            <Footer />
        </main>
    );
}
