import { useNavigate } from "react-router-dom";
import bannerHeadingSvg from "../images/banner-heading.svg";
import ftPng from "../images/ft.png";
import starPng from "../images/star.png";
import "./styles/banner.css";

export default function Banner() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/Product');
    };

    return (
        <section className="banner">
            <div className="container banner-bg-container">
                <img src={starPng} className="banner-star" id="banner-star-1" alt="star" aria-hidden="true" />
                <img src={starPng} className="banner-star" id="banner-star-2" alt="star" aria-hidden="true" />

                <div className="banner-container text-center">
                    <img src={ftPng} className="banner-logo" alt="fintank 3D logo" aria-hidden="true" />

                    <h2 className="banner-title mx-auto">
                        <img src={bannerHeadingSvg} alt="FinTank Finance" role="heading" aria-level={2} />
                    </h2>
                    <h3 className="banner-subtitle">
                        Invest in Tokenized Structured Derivative Notes
                    </h3>
                    <p className="banner-description mx-auto mb-4">
                        FinTank Network is the first structured derivative RWA (real world asset) protocol. We create
                        yielding vault for you to get return from structured equity product to diversify your De-Fi
                        portfolio.
                    </p>

                    <button className="btn btn-fintank-primary" onClick={handleButtonClick}>Trade Now</button>
                </div>
            </div>
        </section>
    );
}
