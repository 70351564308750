import { ethers } from 'ethers';
import { useState } from 'react';
import { CONTRACT_ADDRESS } from '../config/address';
import CONTRACT_ABI from '../config/contract.json';

const useWithdraw = () => {
    const [isInterestPending, setInterestPending] = useState(false);
    const [isPrincipalPending, setPrincipalIsPending] = useState(false);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

    const validateWalletAndNetwork = async () => {
        const accounts = await provider.listAccounts();
        if (accounts.length === 0) {
            alert("Please connect and unlock your MetaMask wallet!");
            return false;
        }

        const network = await provider.getNetwork();
        if (network.chainId !== 11155111) {
            alert("Please connect to the Sepolia test network!");
            return false;
        }

        return true;
    };

    const claimInterest = async (vaultAddress) => {
        if (!(await validateWalletAndNetwork())) return;

        setInterestPending(true);
        try {
            const estimateGas = await contract.estimateGas.claimInterest(vaultAddress);
            const gasLimit = estimateGas.add(ethers.BigNumber.from("10000"));
            const tx = await contract.claimInterest(vaultAddress, { gasLimit: gasLimit });
            await tx.wait();
            alert("Withdraw Interest Success");
        } catch (err) {
            console.error("Claim Interest Error:", err.message);
            alert("Withdraw Interest Failed.");
        } finally {
            setInterestPending(false);
        }
    };

    const claimPrincipal = async (vaultAddress) => {
        if (!(await validateWalletAndNetwork())) return;

        setPrincipalIsPending(true);
        try {
            const estimateGas = await contract.estimateGas.claimPrincipal(vaultAddress);
            const gasLimit = estimateGas.add(ethers.BigNumber.from("10000"));
            const tx = await contract.claimPrincipal(vaultAddress, { gasLimit: gasLimit });
            await tx.wait();
            alert("Withdraw Principal Success");
        } catch (err) {
            console.error("Claim Principal Error:", err.message);
            alert("Withdraw principal Failed.");
        } finally {
            setPrincipalIsPending(false);
        }
    };

    return {
        isInterestPending,
        isPrincipalPending,
        claimInterest,
        claimPrincipal
    };
};

export default useWithdraw;
