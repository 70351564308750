import Footer from "../components/Footer";
import Header from "../components/Header";
import { VAULT_ADDRESS } from "../config/address";
import useWithdraw from "../hooks/useWithdraw";
import iconCopyUrl from "../images/icon-copy.svg";
import "./styles/portfolio.css";

export default function Portfolio() {
	const { claimInterest, claimPrincipal, isInterestPending, isPrincipalPending } = useWithdraw()

	const handleWithdrawInterest = async () => {
		await claimInterest(VAULT_ADDRESS);
	}

	const handleWithdrawPrincipal = async () => {
		await claimPrincipal(VAULT_ADDRESS);
	}

	return (
		<main>
			<Header isRenderAvatar="true" />

			<section className="porfolio-heading">
				<div className="container pt-2">
					<h1>My Portfolio</h1>
					<div>
						<span className="address me-2">0x0000...a1b2c</span>
						<img src={iconCopyUrl} />
					</div>

					<div className="balance-container text-center">
						<h2 className="balance-title">Current Balance:</h2>
						<h3 className="balance-value">$ 100,356.00</h3>
					</div>
				</div>
			</section>

			<section className="container mb-5">
				<h2 className="porfolio-subheading my-4">Investment Summary:</h2>

				<div className="investment-summary-card">
					<div className="investment-summary-card-header">
						<div className="row">
							<div className="col">
								<h3>Electric</h3>
							</div>
							<div className="col card-header-balance">
								<div>PRODUCT BALANCE</div>
								<div className="card-header-balance-value">$ 100,356.00</div>
							</div>
							<div className="col card-header-balance">
								<div>DEPOSITS</div>
								<div className="card-header-balance-value">$ 100,000.00</div>
							</div>
							<div className="col card-header-balance">
								<div>YIELD EARNED</div>
								<div className="card-header-balance-value">$356.00</div>
							</div>
						</div>
					</div>

					<div className="investment-summary-card-body">
						<div className="apy-container d-flex">
							<h4 className="card-amount">$ 10.26</h4>
							<div className="card-apy">
								20% APY
							</div>
						</div>

						<div className="table-container">
							<div className="row">
								<div className="col-5">
									<table className="investment-table">
										<tbody>
											<tr>
												<td className="investment-table-item">balance generating yield</td>
												<td className="investment-table-value">$ 10.00</td>
											</tr>
											<tr>
												<td className="investment-table-item">net yield accruing this trade</td>
												<td className="investment-table-value">$ 0.29</td>
											</tr>
											<tr>
												<td className="investment-table-item">queued withdrawals</td>
												<td className="investment-table-value">0 tokens</td>
											</tr>
											<tr>
												<td className="investment-table-item">Deposits Rolling Over</td>
												<td className="investment-table-value">Deposits Rolling Over</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="col-2">
								</div>
								<div className="col-5">
									<table className="investment-table">
										<tbody>
											<tr>
												<td className="investment-table-item">Trade Start Date</td>
												<td className="investment-table-value">7/13/2023</td>
											</tr>
											<tr>
												<td className="investment-table-item">Trade End / Rollover Date</td>
												<td className="investment-table-value">8/9/2023</td>
											</tr>
											<tr>
												<td className="investment-table-item">Vault Address</td>
												<td className="investment-table-value">0x0000...A1b2c</td>
											</tr>
											<tr>
												<td className="investment-table-item">ARB/ USD KNOCK - IN BARRIER PRICE</td>
												<td className="investment-table-value">$ 0.56</td>
											</tr>
											<tr>
												<td className="investment-table-item">OP/ USD KNOCK - IN BARRIER PRICE</td>
												<td className="investment-table-value">$ 0.64</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<hr className="card-divider" />
						</div>

						<div className="action-container pb-5">
							<div className="action-row">
								<div className="row">
									<div className="col">
										Coupon Token 1
									</div>
									<div className="col text-center">
										Eligible Quantity To Withdraw:
									</div>
									<div className="col text-center">
										10
									</div>
									<div className="col text-right">
										<button onClick={handleWithdrawInterest} disabled={isInterestPending} className="action-button">
											{isInterestPending ? "Loading..." : "Withdraw"}
										</button>
									</div>
								</div>
							</div>
							<div className="action-row">
								<div className="row">
									<div className="col">
										Coupon Token 2
									</div>
									<div className="col text-center">
										Eligible Quantity To Withdraw:
									</div>
									<div className="col text-center">
										0
									</div>
									<div className="col text-right">
										<button onClick={handleWithdrawInterest} disabled={isInterestPending} className="action-button disabled">
											Deposit Now
										</button>
									</div>
								</div>
							</div>
							<div className="action-row">
								<div className="row">
									<div className="col">
										Redemption Token
									</div>
									<div className="col text-center">
										Eligible Quantity To Withdraw:
									</div>
									<div className="col text-center">
										0
									</div>
									<div className="col text-right">
										<button onClick={handleWithdrawInterest} disabled={isInterestPending} className="action-button disabled">
											Withdraw
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</main>
	);
}
