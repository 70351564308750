import logoSvg from "../images/logo.svg";
import "./styles/footer.css";

export default function Footer() {
	return (
		<footer className="fintank-footer">
			<div className="container">
				<div className="footer-logo py-3">
					<img src={logoSvg} alt="FinTank Logo" />
				</div>

				<div className="row">
					<div className="col">
						<div className="footer-links row">
							<div className="col-6">
								<h5>Products</h5>
								<ul>
									<li><a href="/">Bridge</a></li>
									<li><a href="/">Re-staking</a></li>
								</ul>
							</div>
							<div className="col-6">
								<h5>Resources</h5>
								<ul>
									<li><a href="/">Dev</a></li>
									<li><a href="https://drive.google.com/file/d/1z8BqQuiR1v0Wommx9oZU5lwKnrswMCCd/view?usp=drive_link" target="_blank">Docs</a></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="col-auto">
						<div className="footer-social">
							<h5>Follow us</h5>
							<div className="social-links">
								<a href="https://medium.com/@fintank.web3" target="_blank" rel="noreferrer" className="me-3"><i className="bi bi-medium"></i></a>
								<a href="https://www.linkedin.com/company/tank-network/about/" target="_blank" rel="noreferrer" className="me-3"><i className="bi bi-linkedin"></i></a>
								<a href="https://x.com/tankweb3?s=21&t=PTb8Ax34CsEfVa6g7GKrPA" target="_blank" rel="noreferrer" className="me-3"><i className="bi bi-twitter-x"></i></a>
								<a href="https://discord.gg/J4AjC6vp" target="_blank" rel="noreferrer"><i className="bi bi-discord"></i></a>
							</div>
						</div>
					</div>
				</div>

				<p className="copy-right mt-3">&copy; 2024 FinTank. All Right Reserved</p>
			</div>
		</footer>
	);
}
