import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Products from "../components/Products";
import Vaults from "../components/Vaults";
import "./styles/home.css";

export const meta = () => {
	return [
		{ title: "FinTank" },
		{ name: "description", content: "Welcome to FinTank!" },
	];
};

export default function Home() {
	return (
		<main>
			<Header isRenderAvatar="true" />
			<Banner />
			<div className="divider"></div>
			<Products />
			<Vaults />
			<Footer />
		</main>
	);
}
