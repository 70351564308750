import { PureComponent } from "react";
import VaultCard from "./VaultCard";

// styles
import "./styles/vaults.css";
// images
import usdc from "../images/usdc.png";
import usdt from "../images/usdt.png";
import btc from "../images/btc.png";

const vaultCards = [
    {
        title: "Single Stock Vault",
        header: "Electric",
        description:
            "High yield fixed coupon note  linked to leading EV maker TSLA",
        underlyingAssets: "TESLA.O",
        baseYield: "20.00%",
        priceDropProtection: "21.00%",
        tenor: "3 Month",
        navigationLink: "/Product",
        isActive: true,
        coins: [
            {
                title: "USDC Pool",
                imgSrc: usdc,
                disableButton: false,
            },
            {
                title: "USDT Pool",
                imgSrc: usdt,
                disableButton: true,
            },
            {
                title: "BTC Pool",
                imgSrc: btc,
                disableButton: true,
            },
        ],
    },
    {
        title: "Basket Vault",
        header: "Tech Flux",
        description:
            "Aggressive participation note linked to a growth tech basket",
        underlyingAssets: "AMD.O, TSLA.O",
        baseYield: "41.91%",
        priceDropProtection: "1.00%",
        tenor: "3 Month",
        navigationLink: "/",
        isActive: true,
        coins: [
            {
                title: "USDC Pool",
                imgSrc: usdc,
                disableButton: true,
            },
            {
                title: "USDT Pool",
                imgSrc: usdt,
                disableButton: true,
            },
            {
                title: "BTC Pool",
                imgSrc: btc,
                disableButton: true,
            },
        ],
    },
    {
        title: "Single Stock Vault",
        header: "Estee Lauder",
        description: "Conservative strategy note with decent price protection",
        underlyingAssets: "EL.N",
        baseYield: "15.00%",
        priceDropProtection: "13.00%",
        tenor: "3 Month",
        navigationLink: "/",
        isActive: false,
        coins: [
            {
                title: "USDC Pool",
                imgSrc: usdc,
                disableButton: true,
            },
            {
                title: "USDT Pool",
                imgSrc: usdt,
                disableButton: true,
            },
            {
                title: "BTC Pool",
                imgSrc: btc,
                disableButton: true,
            },
        ],
    },
    {
        title: "Single Stock Vault",
        header: "Electric",
        description:
            "High yield fixed coupon note  linked to leading EV maker TSLA",
        underlyingAssets: "TESLA.O",
        baseYield: "20.00%",
        priceDropProtection: "21.00%",
        tenor: "3 Month",
        navigationLink: "/",
        isActive: false,
        coins: [
            {
                title: "USDC Pool",
                imgSrc: usdc,
                disableButton: false,
            },
            {
                title: "USDT Pool",
                imgSrc: usdt,
                disableButton: true,
            },
            {
                title: "BTC Pool",
                imgSrc: btc,
                disableButton: true,
            },
        ],
    },
];

function renderVaultCard() {
    return vaultCards.map((item, index) => {
        return <VaultCard key={index} cardContent={item} />;
    });
}
export default class Vaults extends PureComponent {
    render() {
        return (
            <section className="vaults">
                <div className="container vaults-container">
                    <h2 className="vaults-title text-center my-5">
                        Structured Product Vaults
                    </h2>

                    <div className="row g-5">
                        {renderVaultCard()}
                    </div>
                </div>
            </section>
        );
    }
}
