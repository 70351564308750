import { PureComponent } from "react";
// styles
import "./styles/products.css";
// images

export default class Products extends PureComponent {
    render() {
        return (
            <section className="products">
                <div className="container products-container">
                    <div className="products-container-inner p-5">
                        <h2 className="products-title">
                            PRODUCTS
                        </h2>

                        <h3 className="products-subtitle">
                            FinTank is the first RWA (Real World Asset) structured Derivative DeFi Protocol.
                        </h3>

                        <p className="products-description">
                            We tokenize equity structured notes and offer high yield investment oppotunities linked to real
                            world financial assets to diversive your crypto portfolio.
                        </p>

                        <div className="total-value-locked">
                            TOTAL VALUE LOCKED：<span className="total-value-number">$1M+</span>
                        </div>

                        <button className="btn btn-fintank-primary btn-fintank-primary-inactive" disabled="disabled">Coming
                            Soon</button>
                    </div>
                </div>
            </section>
        );
    }
}
