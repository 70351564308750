import { PureComponent } from "react";
import { Link } from "react-router-dom";
import "./styles/vault-card.css";

export default class VaultCard extends PureComponent {
	render() {
		const { cardContent } = this.props;
		return (
			<div className="col-6">
				<div className="vault-container">
					<div className="vault-header-container">
						<h3>{cardContent.title}</h3>
					</div>

					<div className={cardContent.isActive ? "vault-body p-5 active" : "vault-body p-5 inactive"}>
						<h3 className="vault-subheader text-center">{cardContent.header}</h3>

						<div className="vault-description">
							{cardContent.description}
						</div>

						<table className="vault-intro my-4">
							<tbody>
								<tr>
									<td className="vault-intro-item">Underlying Assets</td>
									<td className="vault-intro-value">{cardContent.underlyingAssets}</td>
								</tr>
								<tr>
									<td className="vault-intro-item">Base Yield</td>
									<td className="vault-intro-value">{cardContent.baseYield}</td>
								</tr>
								<tr>
									<td className="vault-intro-item">Price Drop Protection</td>
									<td className="vault-intro-value">{cardContent.priceDropProtection}</td>
								</tr>
								<tr>
									<td className="vault-intro-item">Tenor</td>
									<td className="vault-intro-value">{cardContent.tenor}</td>
								</tr>
							</tbody>
						</table>

						<Link to={cardContent.navigationLink}>
							<button className="btn btn-fintank-vault-primary mx-auto">Deposit Now</button>
						</Link>
					</div>
				</div>
			</div>
			// {cardContent.coins.map((item, index) => {
			// 	return <Coin key={index} coinContent={item} />;
			// })}
		);
	}
}
