import { Link } from "react-router-dom";
import logo from "../images/logo.svg";
import metamask from "../images/metamask.svg";
import { formatAccountId } from "../services/utils";
import { useWallet } from "../services/walletContextProvider";
import "./styles/header.css";

export default function Header(props) {
	const { isRenderAvatar } = props;
	const { wallet } = useWallet();
	let displayAcc = "";
	if (wallet?.accounts.length) {
		displayAcc = formatAccountId(wallet?.accounts[0]);
	}

	function renderAvatar() {
		return (
			<div className="avatar">
				<img className="metamask" src={metamask} alt="" />
				<Link className="link" to="/portfolio">
					{displayAcc}
				</Link>
			</div>
		);
	}

	return (
		<header className="sticky-top">
			<nav className="navbar navbar-expand-lg navbar-fintank">
				<div className="container d-flex justify-content-between navbar-fintank-container">
					<Link to="/">
						<div className="logo align-middle">
							<img src={logo} alt="FinTank Logo" />
						</div>
					</Link>
					<div className="nav-container align-middle">
						<Link className="nav-link nav-link-margin" to="/Product">Vaults</Link>
						<a href="https://drive.google.com/file/d/1z8BqQuiR1v0Wommx9oZU5lwKnrswMCCd/view?usp=drive_link" target="_blank" className="nav-link nav-link-margin">Docs</a>

						{isRenderAvatar ? renderAvatar() : ""}
					</div>
				</div>
			</nav>
		</header>
	);
}
