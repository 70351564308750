import { Routes, Route } from "react-router-dom";
import { WalletProvider } from "./services/walletContextProvider";
import Portfolio from "./pages/Portfolio";
import Product from "./pages/Product";
import Home from "./pages/Home";

function App() {
	return (
		<WalletProvider>
			<div className="App">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/Product" element={<Product />} />
					<Route path="/portfolio" element={<Portfolio />} />
				</Routes>
			</div>
		</WalletProvider>
	);
}
export default App;
