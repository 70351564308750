import React, { useState, useRef, useEffect } from "react";
import "./styles/currency-dropdown.css";

const CurrencyDropdown = ({ options, defaultOption, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onChange) onChange(option);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="currency-dropdown" ref={dropdownRef}>
            <div className="dropdown-selected" onClick={toggleDropdown}>
                <img
                    src={selectedOption.icon}
                    alt={selectedOption.label}
                    className="dropdown-icon"
                />
                <span className="dropdown-text">{selectedOption.label}</span>
                <span className="dropdown-arrow">▼</span>
            </div>
            {isOpen && (
                <ul className="dropdown-options">
                    {options.map((option) => (
                        <li
                            key={option.value}
                            className="dropdown-option"
                            onClick={() => handleOptionSelect(option)}
                        >
                            <img src={option.icon} alt={option.label} className="dropdown-icon" />
                            <span className="dropdown-text">{option.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CurrencyDropdown;
